import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Formik, useFormik } from "formik";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
    const [errorMessage, setErrorMessage] = useState("");
    
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        const auth = getAuth();
        
        if(values.email){
            sendPasswordResetEmail(auth, values.email)
            .then(() => {
                // Password reset email sent!
                navigate("/");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                setErrorMessage("Email does not exist!");
            });
        }
    };

    const formik = useFormik({
        initialValues: {
          email: "",
        },
        onSubmit,
      });
    return(
        <MDBContainer fluid className="d-flex" style={{height: "100vh", backgroundColor: "#011413", minHeight: "600px"}}>
        <MDBContainer fluid className='w-50 p-2 border-dark rounded text-white my-5 mx-auto' style={{backgroundColor: "#058a85", maxWidth: "400px", minHeight: "520px"}}>
          <form onSubmit={formik.handleSubmit}>
            <MDBCardHeader className="text-center py-4 display-6 fw-bold">
              CodeAPeel
            </MDBCardHeader>
            <MDBContainer className="text-center mt-3">
                <h4 className="fw-bold text-center">Reset Password</h4>
                <p className="d-flex justify-content-center pt-2 px-2">Please enter your email to reset your password!</p>
            </MDBContainer>
            <MDBContainer className="fw-bold px-2 pt-4">
              <label>Email</label>
              <div className="input-group mb-3">
                <input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Email"
                  type="email"
                  className="form-control mb-2 mx-auto w-75"
                />
              </div>
            </MDBContainer>
  
            {errorMessage && 
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            }
            <div className="input-group">
              <button className="btn mb-4 mx-auto border text-white my-2" type="submit" onClick={onSubmit}>
                Reset Password
              </button>
            </div>
            <div className="text-center">
                <p>If you don't have an account <a href="/signup" style={{color:"lightblue"}}>Sign Up</a>.</p>
            </div>
          </form>
        </MDBContainer>
      </MDBContainer>
    )
}

export default ResetPassword;