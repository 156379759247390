import React, {useState, useRef, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {getCurrentUser} from 'aws-amplify/auth';
import {fetchUserAttributes} from 'aws-amplify/auth';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {
    Container,
    Button,
    TextField,
    Typography,
    Box,
    InputAdornment,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    ThemeProvider,
    createTheme, CircularProgress
} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f2669',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontWeight: 700,
            letterSpacing: '-0.02em',
        },
        body1: {
            fontSize: '1.1rem',
            lineHeight: 1.6,
        },
        button: {
            textTransform: 'none',
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '12px 35px',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-1px)',
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.3)',
                    },
                    '&:active': {
                        transform: 'translateY(1px)',
                    }
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 10,
                        '& fieldset': {
                            borderColor: 'rgba(103, 58, 183, 0.3)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(103, 58, 183, 0.5)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#673ab7',
                        },
                    },
                },
            },
        },
    },
});


async function handleFetchUserAttributes() {
    try {
        const userAttributes = await fetchUserAttributes();
        console.log(userAttributes);
    } catch (error) {
        console.log(error);
    }
}

interface Props<T> {
    results?: T[];

    renderItem(item: T): JSX.Element;

    onChange?: React.ChangeEventHandler;
    onSelect?: (item: T) => void;
    onClick: (item: string) => void;
    value?: string;
    url: string;
}

const Mainpage = <T extends object>({
                                        results = [],
                                        renderItem,
                                        value,
                                        url,
                                        onChange,
                                        onClick,
                                        onSelect
                                    }: Props<T>): JSX.Element => {
    const [openNavExternal, setOpenNavExternal] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const resultContainer = useRef<HTMLDivElement>(null);
    const [showResults, setShowResults] = useState(false);
    const [defaultValue, setDefaultValue] = useState("");
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const signOutJwt = useSignOut();
    const auth = getAuth();
    const authJwt = useAuthUser();

    async function handleSignOut() {
        try {
            signOutJwt();
            await signOut(auth);
            setIsLoggedIn(false);
            setUserName("");
            window.location.reload();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                setUserName(user.displayName || user.email || "User");
            } else {
                setIsLoggedIn(false);
                setUserName("");
            }
            setIsLoading(false)
        });

        return () => unsubscribe();
    }, []);

    const navigate = useNavigate();

    const handleSelection = (selectedIndex: number) => {
        const selectedItem = results[selectedIndex];
        if (!selectedItem) return resetSearchComplete();
        navigate("" + selectedItem["url" as keyof T]);
        onSelect && onSelect(selectedItem);
        resetSearchComplete();
    }

    const handleClick = () => {
        onClick(defaultValue);
    }

    const resetSearchComplete = useCallback(() => {
        setFocusedIndex(-1);
        setShowResults(false);
    }, []);

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const {key} = e;
        let nextIndexCount = focusedIndex;
        if (results.length !== 0)
            nextIndexCount = (focusedIndex + 1) % results.length;

        if (key === "ArrowDown")
            nextIndexCount = (focusedIndex + 1) % results.length;

        if (key === "ArrowUp")
            nextIndexCount = (focusedIndex + results.length - 1) % results.length;

        if (key === "Escape")
            resetSearchComplete();

        if (key === "Enter") {
            e.preventDefault();
            handleSelection(focusedIndex);
        }

        setFocusedIndex(nextIndexCount);
    }

    type changeHandler = React.ChangeEventHandler<HTMLInputElement>;
    const handleChange: changeHandler = (e) => {
        setDefaultValue(e.target.value);
        onChange && onChange(e);
    }


    useEffect(() => {
        if (!resultContainer.current) return;
        resultContainer.current.scrollIntoView({
            block: "center",
        });
    }, [focusedIndex]);

    useEffect(() => {
        if (results.length > 0) setShowResults(true);
        if (results.length <= 0) setShowResults(false);
    }, [results]);

    useEffect(() => {
        if (value) setDefaultValue(value);
    }, [value]);

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: `
    radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
    radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
`,
                        backgroundSize: 'cover',
                        backgroundAttachment: 'fixed',
                    }}
                >
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='homepage d-flex align-items-start flex-column' style={{
                maxWidth: "100vw",
                minHeight: "100vh",
                background: `
    radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
    radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
            }}>
                <Navbar/>
                <Container className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'
                           style={{minWidth: "500px"}}>
                    <Box
                        sx={{
                            display: 'inline-block',
                            transition: 'transform 0.3s ease-in-out, text-shadow 0.3s ease-in-out',
                            '&:hover': {
                                textShadow: '0 0 15px rgba(103, 58, 183, 0.5)',
                            },
                        }}
                    >
                        <Typography
                            variant="h1"
                            className="my-5"
                            sx={{
                                color: "#28193f",
                                textAlign: 'center',
                                fontSize: {xs: 60, sm: 80, md: 100},
                                fontWeight: 'bold',
                                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                cursor: 'default',
                                userSelect: 'none',
                                WebkitUserSelect: 'none',
                                MozUserSelect: 'none',
                                msUserSelect: 'none',
                                transition: 'all 0.3s ease-in-out',
                                fontStyle: 'italic',
                                '&:hover': {
                                    transform: 'scale(1.005)',
                                },
                            }}
                        >
                            CodeAPeel
                        </Typography>
                    </Box>
                    {!isLoggedIn ? (
                        <Container className='d-flex justify-content-around mt-4' style={{maxWidth: "70%"}}>
                            <Button
                                className='m-1 mx-4'
                                variant='outlined'
                                onClick={() => navigate("/login")}
                                sx={{
                                    color: '#48287a',
                                    borderColor: '#48287a',
                                    '&:hover': {
                                        borderColor: '#311441',
                                        color: '#311441',
                                        backgroundColor: 'rgba(112,37,133,0.1)',
                                    }
                                }}
                            >
                                Login
                            </Button>
                            <Box sx={{
                                display: 'inline-block',
                                color: 'rgba(94,91,91,0.87)',
                                fontSize: '2rem',
                                fontWeight: 'light',
                                cursor: 'default',
                                userSelect: 'none',
                                WebkitUserSelect: 'none',
                                MozUserSelect: 'none',
                                msUserSelect: 'none',
                            }}>
                                |
                            </Box>
                            <Button
                                variant='contained'
                                className='m-1 mx-4'
                                onClick={() => navigate("/signup")}
                                sx={{
                                    color: '#ffffff',
                                    backgroundColor: '#372259',
                                    '&:hover': {
                                        borderColor: '#311441',
                                        color: '#white',
                                        backgroundColor: '#4c317e',
                                    },
                                }}
                            >
                                Sign Up
                            </Button>
                        </Container>
                    ) : (
                        <Button
                            onClick={handleSignOut}

                            sx={{
                                color: '#ffffff',
                                backgroundColor: '#372259',
                                marginTop: 2,
                                '&:hover': {
                                    borderColor: '#311441',
                                    color: '#white',
                                    backgroundColor: '#4c317e',
                                },
                            }}
                            variant="contained"
                        >
                            Sign Out
                        </Button>
                    )}
                </Container>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}

export default Mainpage;
