import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Button,
    TextField,
    Card,
    CardContent,
    CardActions,
    Grid,
    ThemeProvider,
    createTheme,
    CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import {
    getFirestore,
    collection,
    addDoc,
    getDocs,
    deleteDoc,
    doc,
    serverTimestamp,
    Timestamp,
    DocumentData
} from "firebase/firestore";
import {getApp} from "firebase/app";
import {getAuth, onAuthStateChanged} from "firebase/auth";

const theme = createTheme({
    palette: {

        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '8px 16px',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-1px)',
                        boxShadow: '0 4px 8px rgba(103, 58, 183, 0.3)',
                    },
                    '&:active': {
                        transform: 'translateY(1px)',
                    }
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2b2d30',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-1px)',
                        boxShadow: '0 8px 16px rgba(103, 58, 183, 0.2)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 10,
                        borderColor: '#673ab7',
                        '& fieldset': {
                            color: '#8050d3',
                            borderColor: '#8050d3',
                        },
                        '&:hover fieldset': {
                            borderColor: '#7848ce',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#673ab7',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#d1c4e9',
                    },
                    '& .MuiInputBase-input': {
                        color: '#f0f3f2',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 15,
                    backgroundColor: '#1e1e1e',
                    borderRadius: 16,
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                    minWidth: '60vw',
                    maxWidth: '80vw',
                    minHeight: '30vw',
                    maxHieght: '40vw',
                },
            },
        },

    },
});

interface Post extends DocumentData {
    id: string;
    title: string;
    content: string;
    authorName: string;
    authorId: string;
    createDate: Timestamp;
}

const Blog: React.FC = () => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState<Post[]>([]);
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const auth = getAuth()
    const maxLength = 75
    const app = getApp();
    const db = getFirestore(app);

    useEffect(() => {
        fetchPosts();
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                setIsLoggedIn(true);
                setUserName(user.displayName || "Unknown User");
            } else {
                setIsLoggedIn(false);
            }
            setIsLoading(false)
        });

        return () => unsubscribe();
    }, []);

    const fetchPosts = async () => {
        const querySnapshot = await getDocs(collection(db, "posts"));
        const fetchedPosts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()} as Post));
        setPosts(fetchedPosts);
        setIsLoading(false);
    };

    const handleSubmit = async () => {
        if (newPostTitle && newPostContent && isLoggedIn) {
            await addDoc(collection(db, "posts"), {
                title: newPostTitle,
                content: newPostContent,
                authorName: userName,
                authorId: userId,
                createDate: serverTimestamp(),
            });
            setNewPostTitle('');
            setNewPostContent('');
            setOpenDialog(false);
            fetchPosts();
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDelete = async (postId: string) => {
        await deleteDoc(doc(db, 'posts', postId));
        fetchPosts();
    };

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `
            radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
            radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
          `,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}>
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }


    function sliceContent(content: string) {
        if (content.length <= maxLength) return content;
        return content.slice(0, maxLength) + '...';
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                background: `
          radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
          radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
        `,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
            }}>
                <Navbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4, flexGrow: 1}}>
                    <Typography variant="h2" gutterBottom sx={{
                        WebkitUserSelect: 'none',
                        MozUserSelect: 'none',
                        msUserSelect: 'none',
                        color: '#402f56', textAlign: 'center', mb: 4
                    }}>
                        CodeAPeel Blog
                    </Typography>
                    {isLoggedIn && (
                        <Box sx={{display: 'flex', justifyContent: 'center', mb: 4}}>
                            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                                Create New Post
                            </Button>
                        </Box>
                    )}

                    <Grid container spacing={10}>
                        {posts.map((post) => (
                            <Grid item xs={12} sm={6} md={4} key={post.id}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {sliceContent(post.title)}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {sliceContent(post.content)}
                                        </Typography>
                                        <Typography variant="caption" display="block" sx={{mt: 2}}>
                                            By: {post.authorName}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{mt: 'auto'}}>
                                        <Button sx={{color:'#764fbb',}} size="small" onClick={() => navigate(`/context?id=${post.id}`)}>
                                            Read More
                                        </Button>
                                        {userId === post.authorId && (
                                            <Button size="small" color="error" onClick={() => handleDelete(post.id)}>
                                                Delete
                                            </Button>
                                        )}
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Footer/>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Create New Post</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 2}}>
                        <TextField
                            fullWidth
                            label="Post Title"
                            value={newPostTitle}
                            onChange={(e) => setNewPostTitle(e.target.value)}
                            margin="normal"
                        />
                        <TextField sx={{flexGrow: 1}}
                                   fullWidth
                                   label="Post Content"
                                   value={newPostContent}
                                   onChange={(e) => setNewPostContent(e.target.value)}
                                   margin="normal"
                                   multiline
                                   rows={10}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color="error" onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Submit Post
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default Blog;