import { useFormik } from "formik";
import { MDBContainer } from "mdb-react-ui-kit";
import { confirmSignUp, type ConfirmSignUpInput } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";

interface Props<T>{
    userEmail?: string;
}

function ConfirmSignup <T extends object>({userEmail}:Props<T>) {
  const navigate = useNavigate();

  async function handleSignUpConfirmation({
    username,
    confirmationCode
  }: ConfirmSignUpInput) {
    try {
      console.log({username, confirmationCode})
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode
      });
      navigate("/login");
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  const onSubmit = async (values: any) => {
        handleSignUpConfirmation(values)
    }

    const formik = useFormik({
        initialValues: {
            username: userEmail,
            confirmationCode: ""
        },
        onSubmit,
    });
    return(
        <MDBContainer fluid className="d-flex" style={{height: "100vh", backgroundColor: "#011413"}}>
            <MDBContainer fluid className='w-50 p-2 border-dark rounded text-white my-5 mx-auto' style={{backgroundColor: "#058a85", maxWidth: "400px", height: "300px"}}>
            <form onSubmit={formik.handleSubmit}>
                <MDBContainer className="text-center mt-3">
                    <h4 className="fw-bold text-center">Confirm your email!</h4>
                    <p className="d-flex justify-content-center pt-2 px-2">Confirmation code sent to:</p>
                    <strong>{userEmail}</strong>
                </MDBContainer>
                <hr className="border border-light text-light" style={{opacity: "80%"}}/>
                <MDBContainer className="fw-bold px-2 my-2">
                <label>Code</label>
                <div className="input-group mb-3">
                    <input
                    name="confirmationCode"
                    value={formik.values.confirmationCode}
                    onChange={formik.handleChange}
                    placeholder="Verification Code"
                    type="text"
                    className="form-control mb-1 mx-auto rounded"
                    />
                </div>
                </MDBContainer>
                <div className="input-group">
                <button className="btn mb-4 mx-auto border text-white" type="submit" onClick={onSubmit}>
                    Confirm
                </button>
                </div>
            </form>
            </MDBContainer>
        </MDBContainer>
    )
}

export default ConfirmSignup;