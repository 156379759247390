import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
    Container,
    Typography,
    Button,
    Box,
    ThemeProvider,
    createTheme,
    CircularProgress,
    keyframes,
} from "@mui/material";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    padding: '12px 30px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.3)',
                    },
                },
            },
        },
    },
});

function Technology() {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const auth = getAuth();
    const navigate = useNavigate();
    const storage = getStorage();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (user.displayName) {
                    setName(user.displayName);
                }
                getUrl();
            } else {
                navigate("/login");
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    async function getUrl() {
        try {
            const downloadUrl = await getDownloadURL(ref(storage, 'CodeAPeel-C-V-6.zip'));
            setUrl(downloadUrl);
        } catch (error) {
            console.error("Error fetching download URL:", error);
        }
    }

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: `
                        radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
                        radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)`,
                        backgroundSize: 'cover',
                        backgroundAttachment: 'fixed',
                    }}
                >
                    <CircularProgress />
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: `
                    radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
                    radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%) `,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}
            >
                <Navbar />
                <Container
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        py: 4,
                    }}
                >
                    <Typography variant="h3" component="h1" gutterBottom color="primary">
                        Welcome {name}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You have a limited edition user account that grants access to download the light edition of
                        CodeAPeel-C. When launching CodeAPeel, you will be prompted to enter a password. Please use the
                        same password as your login for the CodeAPeel website. Note that this site is still under
                        construction, so some links may not function as expected. This page is provided to offer a link
                        for those interested in trying out CodeAPeel-C.
                    </Typography>
                    <Typography variant="body1" paragraph fontWeight="bold">
                        Important note:
                    </Typography>
                    <Typography variant="body2" paragraph>
                        You are allowed to copy and compile this program for personal use. However, no part of this
                        program may be reproduced, distributed, or stored in any form, including in a database or
                        retrieval system, without prior written permission from AlgoritiX Corporation. AlgoritiX makes
                        no claims, either direct or implied, regarding the program's accuracy, functionality, or
                        performance, nor does it guarantee the safety of the software on your device. By installing or
                        using this software, you agree to these terms.
                    </Typography>

                    {url && (
                        <Button
                            variant="contained"
                            color="secondary"
                            href={url}
                            target="_blank"
                            download="CodeAPeel-C"
                            sx={{
                                color: '#ffffff',
                                alignSelf: 'flex-start',
                                background: 'linear-gradient(45deg, #673ab7 30%, #9c27b0 90%)',
                                marginTop: 2,
                                position: 'relative',
                                overflow: 'hidden',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #5e35b1 30%, #8e24aa 90%)',
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'rgba(255, 255, 255, 0.2)',
                                    transform: 'translateX(-100%)',
                                    transition: 'transform 0.3s ease',
                                },
                                '&:hover::after': {
                                    transform: 'translateX(100%)',
                                },
                            }}
                        >
                            Download CodeAPeel-C
                        </Button>
                    )}
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default Technology;