import React, {useEffect, useState} from "react";
import {AppBar, Toolbar, Button, Grid, Menu, MenuItem, IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import logoImage from '../../images/codeapeellogo2.png'
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";


const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontWeight: 500,
        },
        button: {
            textTransform: 'none',
            fontWeight: 500,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(90deg, #1e1e1e 0%, #2c2c2c 100%)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                    position: 'static',
                    top: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '6px 16px',
                    transition: 'all 0.3s ease-in-out',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '2px',
                        backgroundColor: '#673ab7',
                        transform: 'scaleX(0)',
                        transition: 'transform 0.3s ease-in-out',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                        '&::after': {
                            transform: 'scaleX(1)',
                        },
                    },
                    '&:active': {
                        transform: 'translateY(1px)',
                    }
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        transform: 'rotate(5deg) scale(1.1)',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        color: '#673ab7',
                        transform: 'scale(1.02)',
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1e1e1e',
                },
                list: {
                    padding: 1,
                },
            }
        },
    },
});

function Navbar() {
    const navigate = useNavigate();

    const [userName, setUserName] = useState("");

    const [techAnchorEl, setTechAnchorEl] = useState<null | HTMLElement>(null);
    const [blogAnchorEl, setBlogAnchorEl] = useState<null | HTMLElement>(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const auth = getAuth();
    const signOut = useSignOut();
    const isTechMenuOpen = Boolean(techAnchorEl); // Check if the menu is open
    const isBlogMenuOpen = Boolean(blogAnchorEl); // Check if the menu is open

    const handleTechDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTechAnchorEl(event.currentTarget);
    };

    const handleTechDropdownClose = () => {
        setTechAnchorEl(null);
    };


    const handleBlogDropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBlogAnchorEl(event.currentTarget);
    };

    const handleBlogDropdownClose = () => {
        setBlogAnchorEl(null);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                setUserName(user.displayName || user.email || "User");
            } else {
                setIsLoggedIn(false);
                setUserName("");
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" color="primary" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        onClick={() => navigate("/")}
                        sx={{marginRight: 2, padding: 0}}
                    >
                        <img
                            src={logoImage}
                            alt="CodeAPeel Logo"
                            style={{width: 40, height: 40, objectFit: 'contain'}}
                        />
                    </IconButton>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Button
                                color="inherit"
                                onMouseEnter={handleTechDropdownOpen}
                                aria-controls="technology-menu"
                                aria-haspopup="true"
                                sx={{
                                    transition: 'all 0.3s ease-in-out',
                                    backgroundColor: isTechMenuOpen ? 'transparent' : 'transparent',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '2px',
                                        backgroundColor: '#673ab7',
                                        transform: isTechMenuOpen ? 'scaleX(1)' : 'scaleX(0)',
                                        transition: 'transform 0.3s ease-in-out',
                                    },
                                    '&:hover::after': {
                                        transform: 'scaleX(1)',
                                    },
                                }}
                            >
                                Technology
                            </Button>
                            <Menu
                                id="technology-menu"
                                anchorEl={techAnchorEl}
                                open={Boolean(techAnchorEl)}
                                onClose={handleTechDropdownClose}
                                MenuListProps={{onMouseLeave: handleTechDropdownClose}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-C-Web/Overview.html";
                                    handleTechDropdownClose();
                                }}>
                                    CodeAPeel C
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-H-Web/Overview.html";
                                    handleTechDropdownClose();
                                }}>
                                    CodeAPeel H
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.location.href = "https://www.codeapeel.org/CodeAPeel-M-Web/Overview.html";
                                    handleTechDropdownClose();
                                }}>
                                    CodeAPeel M
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item>
                            {isLoggedIn && (
                                <Button color="inherit" onClick={() => navigate("/technology")}>
                                    Download
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/blog")}>
                                Blog
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/support")}>
                                Support
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/content")}>
                                Content
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate("/order")}>
                                How to order
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
               </AppBar>
         </ThemeProvider>
    );
}

export default Navbar;